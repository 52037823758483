import * as tslib_1 from "tslib";
import { EntityStore, StoreConfig } from '@datorama/akita';
import * as i0 from "@angular/core";
let StudentsStore = class StudentsStore extends EntityStore {
    constructor() {
        super();
    }
};
StudentsStore.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StudentsStore_Factory() { return new StudentsStore(); }, token: StudentsStore, providedIn: "root" });
StudentsStore = tslib_1.__decorate([
    StoreConfig({ name: 'students' }),
    tslib_1.__metadata("design:paramtypes", [])
], StudentsStore);
export { StudentsStore };
