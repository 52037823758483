import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';

import { IonColorName } from '@modules/shared/common-types';

const DEFAULT_SNACK_OPTIONS: SnackOptions = {
  duration: 3000,
  message: '',
  color: IonColorName.dark,
  showCloseButton: false,
};

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {

  constructor(
    private readonly toastService: ToastController,
  ) { }

  public createSnack(messageOrOptions: string | SnackOptions): Promise<onDidDismissEvent> {
    let options = {...DEFAULT_SNACK_OPTIONS};

    if (typeof(messageOrOptions) === 'string') {
      options.message = messageOrOptions;
    } else {
      options = {
        ...options,
        ...messageOrOptions,
      };
    }

    return Promise.resolve(
      this.toastService.create({
        ...options,
        closeButtonText: 'Fechar',
        mode: 'ios',
      }).then(toast => {
        toast.present();

        return toast.onDidDismiss();
      }),
    );
  }

  createErrorSnack(messageOrOptions: string | SnackOptions): Promise<onDidDismissEvent> {
    const options = typeof(messageOrOptions) === 'string' ? { message: messageOrOptions } : messageOrOptions;

    return this.createSnack({ duration: 5000, ...options, color: IonColorName.danger });
  }
}

interface SnackOptions {
  color?: IonColorName;
  duration?: number;
  message: string;
  showCloseButton?: boolean;
}

type onDidDismissEvent = OverlayEventDetail;
