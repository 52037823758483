import { Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { BackGuard } from '@guards/back.guard';
import { NoAccessGuard } from '@guards/no-access.guard';
import { ParamRegexGuard } from '@guards/param-regex.guard';
import { StaffUserGuard } from '@guards/staff-user.guard';
import { InstitutionResolveService } from '@services/institution-resolve.service';
import { StudentsResolveService } from '@services/students-resolve.service';
const ɵ0 = () => import("./modules/lgpd/lgpd.page.module.ngfactory").then(m => m.LgpdPageModuleNgFactory), ɵ1 = () => import("./modules/bhave-parents/bhave-parents.module.ngfactory").then(m => m.BhaveParentsPageModuleNgFactory), ɵ2 = () => import("./modules/internal-admin/internal-admin.module.ngfactory").then(m => m.InternalAdminPageModuleNgFactory), ɵ3 = () => import("./modules/staff/staff.module.ngfactory").then(m => m.StaffPageModuleNgFactory), ɵ4 = () => import("./modules/evento/evento.module.ngfactory").then(m => m.EventoPageModuleNgFactory), ɵ5 = () => import("./modules/not-found/not-found.module.ngfactory").then(m => m.NotFoundPageModuleNgFactory), ɵ6 = () => import("./modules/registration-invite/registration-invite.module.ngfactory").then(m => m.RegistrationInviteModuleNgFactory), ɵ7 = () => import("./modules/forgot-password/forgot-password.module.ngfactory").then(m => m.ForgotPasswordPageModuleNgFactory), ɵ8 = () => import("./modules/redefine-password/redefine-password.module.ngfactory").then(m => m.RedefinePasswordModuleNgFactory), ɵ9 = () => import("./modules/login/login.page.module.ngfactory").then(m => m.LoginPageModuleNgFactory), ɵ10 = () => import("./modules/no-access/no-access.module.ngfactory").then(m => m.NoAccessPageModuleNgFactory), ɵ11 = () => import("./modules/institution-select/institution-select.module.ngfactory").then(m => m.InstitutionSelectPageModuleNgFactory), ɵ12 = { regexList: ['^([0-9]+)$'], params: ['institutionId'], redirectTo: 'pagina-nao-encontrada' }, ɵ13 = () => import("./modules/main-layout/main-layout.module.ngfactory").then(m => m.MainLayoutModuleNgFactory), ɵ14 = () => import("./modules/delete-account/delete-account.module.ngfactory").then(m => m.DeleteAccountPageModuleNgFactory);
const routes = [
    { path: 'como-excluir-sua-conta', loadChildren: ɵ0 },
    {
        path: 'bhave-parents',
        loadChildren: ɵ1,
    },
    {
        path: 'admin',
        loadChildren: ɵ2,
        canActivate: [AuthGuard, StaffUserGuard],
    },
    {
        path: 'suporte',
        loadChildren: ɵ3,
        canActivate: [AuthGuard, StaffUserGuard],
    },
    { path: 'evento', loadChildren: ɵ4 },
    { path: 'pagina-nao-encontrada', loadChildren: ɵ5 },
    {
        path: 'convite',
        loadChildren: ɵ6,
    },
    {
        path: 'esqueci-minha-senha',
        loadChildren: ɵ7,
    },
    {
        path: 'redefinir-senha',
        loadChildren: ɵ8,
    },
    { path: 'login', loadChildren: ɵ9 },
    {
        path: 'sem-acesso',
        loadChildren: ɵ10,
        canActivate: [AuthGuard],
    },
    {
        path: 'instituicoes',
        loadChildren: ɵ11,
        canActivate: [AuthGuard, NoAccessGuard],
    },
    {
        path: ':institutionId',
        canActivate: [AuthGuard, ParamRegexGuard, NoAccessGuard],
        canDeactivate: [BackGuard],
        data: ɵ12,
        resolve: { institution: InstitutionResolveService, students: StudentsResolveService },
        loadChildren: ɵ13,
    },
    {
        path: 'remover-conta',
        loadChildren: ɵ14,
    },
    { path: '**', redirectTo: 'login' },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
