import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { onlyDigits } from '@utils/convertions';

import * as moment from 'moment';

export class CustomValidators {

  static cellPhone(control: AbstractControl): ValidationErrors {
    const cellPhone = onlyDigits(control.value);

    if (cellPhone === '') {
      return undefined;
    }

    const minLength = 10;
    const maxLength = 11;
    const cellPhoneError = { cellPhone: true };

    if (cellPhone.length < minLength || cellPhone.length > maxLength) {
      return cellPhoneError;
    }
  }

  static phoneNumber(control: AbstractControl): ValidationErrors {
    const phoneNumber = onlyDigits(control.value);

    if (phoneNumber === '') {
      return undefined;
    }

    const phoneNumberLength = 10;

    if (phoneNumber.length !== phoneNumberLength) {
      return { phoneNumber: true };
    }
  }

  static cpf(control: AbstractControl): ValidationErrors {
    const cpf = control.value.replace(/\D/g, '');
    const requiredLength = 11;
    const idxModifier = 2;

    const cpfError = { cpf: true, message: 'CPF Inválido' };

    if (cpf.length !== requiredLength) {
      return { ...cpfError, cpf: { requiredLength }};
    }

    // Verifica se todos os dígitos são iguais.
    if (/^([0-9])\1*$/.test(cpf)) {
      return { ...cpfError, cpf: { equalDigits: true }};
    }

    const buildDigit = (arr: number[]): number => {
      const digit = arr
        .map((val, idx) => val * (idx + idxModifier))
        .reduce((total, current) => total + current) % requiredLength;

      if (digit < idxModifier) {
        return digit < idxModifier ? 0 : requiredLength - digit;
      }

      return requiredLength - digit;
    };

    // A seguir é realizado o cálculo verificador.
    const cpfArr: number[] = cpf.split('').reverse().slice(idxModifier);

    cpfArr.unshift(buildDigit(cpfArr));
    cpfArr.unshift(buildDigit(cpfArr));

    if (cpf !== cpfArr.reverse().join('')) {
      // Dígito verificador não é válido, resultando em falha.
      return { ...cpfError, cpf: { digit: true }};
    }

    return undefined;
  }

  static matchPassword(control: AbstractControl): ValidationErrors {
    const password = control.get('new_password').value;
    const password_confirm = control.get('confirm_password').value;
    if (password !== password_confirm) {
      control.get('confirm_password')
        .setErrors({ matchPassword: true, message: 'As senhas devem ser idênticas' });
    } else {
      return undefined;
    }
  }

  static matchPassword2(control: AbstractControl): ValidationErrors {
    const password = control.get('password').value;
    const password_confirm = control.get('password_confirm').value;
    if (password !== password_confirm) {
      control.get('password_confirm')
        .setErrors({ matchPassword: true, message: 'As senhas devem ser idênticas' });
    } else {
      return undefined;
    }
  }

  static ensureDatesChronology(
    beforeDate: { controlName: string, label: string },
    afterDate: { controlName: string, label: string },
  ): (form: FormGroup) => void {
    return (form: FormGroup) => {
      const beforeControl = form.get(beforeDate.controlName);
      const afterControl = form.get(afterDate.controlName);

      if (!beforeControl || !afterControl) {
        return;
      }

      const beforeMoment = moment(beforeControl.value);
      const afterMoment = moment(afterControl.value);

      if (!beforeMoment.isValid() || !afterMoment.isValid()) {
        return;
      }

      if (beforeMoment.isSameOrAfter(afterMoment)) {
        afterControl.setErrors({ dateShouldBeAfter: { label: beforeDate.label } });
        beforeControl.setErrors({ dateShouldBeBefore: { label: afterDate.label } });
      } else {
        if (afterControl.hasError('dateShouldBeAfter')) {
          afterControl.updateValueAndValidity();
        }
        if (beforeControl.hasError('dateShouldBeBefore')) {
          beforeControl.updateValueAndValidity();
        }
      }
    };
  }
}

const VALIDATION_ERRORS = {
  required: () =>             `Este campo é obrigatório.`,
  minlength: (errorData) =>   `Tamanho mínimo de ${errorData.requiredLength} caracteres.`,
  maxlength: (errorData) =>   `Tamanho máximo de ${errorData.requiredLength} caracteres.`,
  email: () =>                `E-mail inválido.`,
  matchPassword: () =>        `As senhas devem ser idênticas.`,
  cpf: () =>                  `CPF inválido`,
  cnpj: () =>                 `CNPJ inválido`,
  cellPhone: () =>            `Celular inválido`,
  phoneNumber: () =>          `Telefone inválido`,
  submitError: (errorData) => `${errorData.message}`,
  dateShouldBeBefore: (errorData) => `Insira uma data antes de "${errorData.label}"`,
  dateShouldBeAfter: (errorData) =>  `Insira uma data depois de "${errorData.label}"`,
  default:                    `Campo inválido`,
};

const HTTP_RESPONSE_ERRORS = {
  offline: 'Verifique sua conexão e tente novamente',
  default: 'Erro ao tentar enviar. Tente novamente',
};

export function getHttpErrorMessage(errorKey: string) {
  const error = HTTP_RESPONSE_ERRORS[errorKey];

  return error ? error : HTTP_RESPONSE_ERRORS.default;
}

export function getErrorMessage(errorKey: string, errorData: ValidationErrors): string {
  const error = VALIDATION_ERRORS[errorKey];

  return error ? error(errorData) : VALIDATION_ERRORS.default;
}
