import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { FormErrorComponent, MaterialLibModule } from '@bhave/material-lib';
import { TextMaskModule } from 'angular2-text-mask';

import { CenteredLayoutComponent } from './centered-layout/centered-layout.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { ErrorMessageDirective } from './control-error-message.directive';
import { DialogComponent } from './dialog/dialog.component';
import { ExpandableItemComponent } from './expandable-item/expandable-item.component';
import { FeedbackForumComponent } from './feedback-forum/feedback-forum.component';
import { FooterActionBarComponent } from './footer-action-bar/footer-action-bar.component';
import { AddressInlinePipe } from './pipes/address-inline.pipe';
import { BrDateCalendarPipe } from './pipes/br-date-calendar.pipe';
import { BrDatePipe } from './pipes/br-date.pipe';
import { BrDayOfWeekPipe } from './pipes/br-day-of-week.pipe';
import { BrTimePipe } from './pipes/br-time.pipe';
import { ConformToMaskPipe } from './pipes/conform-to-mask.pipe';
import { CpfFormatPipe } from './pipes/cpf-format.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { HumanizeBooleanPipe } from './pipes/humanize-boolean.pipe';
import { IsSelectedPipe } from './pipes/is-selected.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { SkeletonListComponent } from './skeleton-list/skeleton-list.component';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { TooltipComponent } from './tooltip/tooltip.component';

@NgModule({
  declarations: [
    AddressInlinePipe,
    BrDateCalendarPipe,
    BrDatePipe,
    BrDayOfWeekPipe,
    BrTimePipe,
    CenteredLayoutComponent,
    ContextMenuComponent,
    CpfFormatPipe,
    DialogComponent,
    FeedbackForumComponent,
    SkeletonListComponent,
    StatusBarComponent,
    ErrorMessageDirective,
    ExpandableItemComponent,
    DurationPipe,
    FooterActionBarComponent,
    TooltipComponent,
    ConformToMaskPipe,
    HumanizeBooleanPipe,
    IsSelectedPipe,
    SearchbarComponent,
    SafeUrlPipe,
  ],
  imports: [
    CommonModule,
    IonicModule,
    MaterialLibModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
  ],
  entryComponents: [
    ContextMenuComponent,
    DialogComponent,
    FeedbackForumComponent,
    FormErrorComponent,
    TooltipComponent,
  ],
  exports: [
    AddressInlinePipe,
    BrDateCalendarPipe,
    BrDatePipe,
    BrDayOfWeekPipe,
    BrTimePipe,
    CenteredLayoutComponent,
    CpfFormatPipe,
    DialogComponent,
    SkeletonListComponent,
    StatusBarComponent,
    ErrorMessageDirective,
    ExpandableItemComponent,
    DurationPipe,
    FooterActionBarComponent,
    ConformToMaskPipe,
    HumanizeBooleanPipe,
    IsSelectedPipe,
    SearchbarComponent,
    SafeUrlPipe,
  ],
})
export class SharedModule {}
