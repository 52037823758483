import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';

import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from '@utils/constants/http-status-code';
import { environment } from '../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    // TODO: reevaluate the integrations workaround
    Sentry.init({
      dsn: environment.sentry_dsn,
      environment: 'production',
      integrations(integrations) {
        return integrations.filter(i => i.name !== 'TryCatch');
      },
    });
  }

  handleError(error) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === HttpStatusCode.UNAUTHORIZED) {
        // this was catched on src/app/interceptors/authentication-token.interceptor.ts
        return;
      }
    }

    Sentry.captureException(error.originalError || error);
  }
}
