import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { of } from 'rxjs';

import { DialogButton, IonButtonRole, IonColorName } from '../common-types';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {

  @Input() buttons: DialogButton[];
  @Input() header: string;
  public localButtons: DialogButton[];
  @Input() message: string;

  constructor(
    private readonly popoverController: PopoverController,
  ) { }

  async executeHandler(handler: () => Promise<boolean | void> | boolean | void): Promise<void> {
    const handlerResult = handler();

    let shouldDismiss: boolean | void;

    shouldDismiss = handlerResult instanceof Promise
      ? await handlerResult
      : handlerResult;

    if (shouldDismiss !== false) {
      const dialog = await this.popoverController.getTop();

      if (dialog) {
        dialog.dismiss();
      }
    }
  }

  ngOnInit() {
    this.localButtons = this.buttons.map(button => ({
      ...button,
      color:    button.role === IonButtonRole.cancel ? IonColorName.dark : button.color,
      handler:  button.role === IonButtonRole.cancel && !button.handler ? () => this.popoverController.dismiss() : button.handler,
      disabled: typeof(button.disabled) === 'boolean' ? of(button.disabled) : button.disabled,
      loading:  typeof(button.loading)  === 'boolean' ? of(button.loading)  : button.loading,
    }));
  }

  trackByFn(index: number) {
    return index;
  }

}
