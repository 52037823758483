/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback-forum.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../node_modules/@bhave/material-lib/bhave-material-lib.ngfactory";
import * as i5 from "@bhave/material-lib";
import * as i6 from "./feedback-forum.component";
import * as i7 from "@angular/platform-browser";
var styles_FeedbackForumComponent = [i0.styles];
var RenderType_FeedbackForumComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackForumComponent, data: {} });
export { RenderType_FeedbackForumComponent as RenderType_FeedbackForumComponent };
export function View_FeedbackForumComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 11, "ion-toolbar", [["color", "light"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 5, "ion-buttons", [["slot", "start"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(5, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "ion-button", [["fill", "clear"], ["shape", "round"], ["size", "large"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(7, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { fill: [0, "fill"], shape: [1, "shape"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "bhvmat-icon", [["name", "close"], ["slot", "icon-only"]], null, null, null, i4.View_IconComponent_0, i4.RenderType_IconComponent)), i1.ɵdid(9, 114688, null, 0, i5.IconComponent, [i1.ElementRef], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 3, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(11, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["F\u00F3rum de sugest\u00F5es"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(15, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(16, 0, null, 0, 0, "iframe", [["frameBorder", "0"], ["height", "100%"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "light"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "clear"; var currVal_2 = "round"; var currVal_3 = "large"; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "close"; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.urlSafe; _ck(_v, 16, 0, currVal_5); }); }
export function View_FeedbackForumComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feedback-forum", [], null, null, null, View_FeedbackForumComponent_0, RenderType_FeedbackForumComponent)), i1.ɵdid(1, 114688, null, 0, i6.FeedbackForumComponent, [i7.DomSanitizer, i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedbackForumComponentNgFactory = i1.ɵccf("app-feedback-forum", i6.FeedbackForumComponent, View_FeedbackForumComponent_Host_0, {}, {}, []);
export { FeedbackForumComponentNgFactory as FeedbackForumComponentNgFactory };
