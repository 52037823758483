import { AlertButton } from '@ionic/core';
import { Observable } from 'rxjs';

export enum IonColorName {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  light = 'light',
  medium = 'medium',
  dark = 'dark',
}

export enum IonButtonRole {
  cancel = 'cancel',
}

export interface DialogButton extends AlertButton {
  color?: IonColorName;
  disabled?: Observable<boolean>;
  handler?: () => boolean | void | {};
  loading?: Observable<boolean>;
}
