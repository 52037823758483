import { KeyValue } from '@angular/common';

import * as moment from 'moment';

import { DISPLAY_DATE_FORMAT, DISPLAY_DATE_FORMAT_FULL, HTML_DATE_FORMAT_FULL } from './constants/date-constants';

export function enumToKeyValueArray<EnumType>(enumeration: EnumType): KeyValue<string, string >[] {
  const HALF = 2;
  const keys = Object.keys(enumeration);
  const values = Object.values(enumeration);
  const result: KeyValue<string, any>[] = [];

  for (let i = 0; i < keys.length / HALF; i++) {
    const obj: KeyValue<string, string > = {
      key: keys[i],
      value: values[i],
    };
    result.push(obj);
  }

  return result;
}

export function groupBy<T>(
  list: T[],
  key: string,
  compareFn = (a: any, b: any) => a === b,
  initialValue?: GroupedArray<T>[],
): GroupedArray<T>[] {
  const finalList: GroupedArray<T>[] = initialValue ? initialValue : [];

  list.forEach((item: T) => {
    const matchGroup = finalList.find(i => compareFn(i[key], item[key]));

    if (matchGroup === undefined) {
      finalList.push({
        [key]: item[key],
        group: [item],
      });
    } else {
      matchGroup.group.push(item);
    }
  });

  return finalList;
}

export function onlyDigits(value: string): string {
  return value.replace(/\D/g, '');
}

export interface GroupedArray<T> {
  group: T[];
  [key: string]: any;
}

export function displayFullDate(date: Date): string {
  const momentDate = moment(date, [DISPLAY_DATE_FORMAT_FULL, HTML_DATE_FORMAT_FULL]);

  return momentDate.isValid() ? momentDate.format(DISPLAY_DATE_FORMAT_FULL) : undefined;
}

export function displayDate(date: Date): string {
  const momentDate = moment(date, [DISPLAY_DATE_FORMAT, HTML_DATE_FORMAT_FULL]);

  return momentDate.isValid() ? momentDate.format(DISPLAY_DATE_FORMAT) : undefined;
}
