import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { of } from 'rxjs';
import { IonButtonRole, IonColorName } from '../common-types';
export class DialogComponent {
    constructor(popoverController) {
        this.popoverController = popoverController;
    }
    executeHandler(handler) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const handlerResult = handler();
            let shouldDismiss;
            shouldDismiss = handlerResult instanceof Promise
                ? yield handlerResult
                : handlerResult;
            if (shouldDismiss !== false) {
                const dialog = yield this.popoverController.getTop();
                if (dialog) {
                    dialog.dismiss();
                }
            }
        });
    }
    ngOnInit() {
        this.localButtons = this.buttons.map(button => (Object.assign({}, button, { color: button.role === IonButtonRole.cancel ? IonColorName.dark : button.color, handler: button.role === IonButtonRole.cancel && !button.handler ? () => this.popoverController.dismiss() : button.handler, disabled: typeof (button.disabled) === 'boolean' ? of(button.disabled) : button.disabled, loading: typeof (button.loading) === 'boolean' ? of(button.loading) : button.loading })));
    }
    trackByFn(index) {
        return index;
    }
}
