import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';


@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {

  @Input() header: string;
  @Input() text: string;

  constructor(private readonly popoverController: PopoverController) { }

  close() {
    this.popoverController.dismiss();
  }
}
