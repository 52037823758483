import { StudentsService } from './students.service';
import * as i0 from "@angular/core";
import * as i1 from "./students.service";
export class StudentsResolveService {
    constructor(studentsService) {
        this.studentsService = studentsService;
    }
    resolve() {
        this.studentsService.list().subscribe();
        return undefined;
    }
}
StudentsResolveService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StudentsResolveService_Factory() { return new StudentsResolveService(i0.ɵɵinject(i1.StudentsService)); }, token: StudentsResolveService, providedIn: "root" });
