<ion-item
  *ngFor="let item of localItems; trackBy: trackByFn"
  button
  lines="none"
  (click)="item.checkbox ? null : itemClicked(item)"
  [routerLink]="item.routerLink !== undefined ? item.routerLink : undefined"
>
  <ion-label>{{ item.title }}</ion-label>
  <ion-badge *ngIf="item.badgeValue | async" mode="ios" slot="end">{{ item.badgeValue | async }}</ion-badge>
  <bhvmat-icon *ngIf="item.iconName !== undefined && !item.checkbox" slot="start" [name]="item.iconName"></bhvmat-icon>
  <ion-checkbox #chkbox *ngIf="item.checkbox" [checked]="item.checked" slot="start" (ionChange)="checkboxClicked($event, item)"></ion-checkbox>
</ion-item>
