
import { FormBuilder, FormGroup } from '@angular/forms';

import { BehaviorSubject, Observable } from 'rxjs';

import { FormErrors } from '@utils/forms/form-errors';

export interface FormRequest<T> {
  request: () => Observable<T>;
  onSuccess?: (response: T) => void;
  onError?: (error: any) => void;
}

export abstract class BaseForm<T> extends FormErrors {

  abstract form: FormGroup;
  sending$ = new BehaviorSubject(false);
  formError: string;
  protected formBuilder: FormBuilder = new FormBuilder();

  protected abstract get formRequest(): FormRequest<T>;

  constructor() {
    super();
  }

  public submit(): void {
    if (this.form.invalid) {
      this.checkForControlErrors();

      return;
    }

    this.sending$.next(true);
    this.formError = undefined;

    this.formRequest.request().subscribe(
      response => {
        this.sending$.next(false);
        if (this.formRequest.onSuccess) {
          this.formRequest.onSuccess(response);
        }
      },
      error => {
        this.sending$.next(false);
        super.handleSubmitError(error);
        if (this.formRequest.onError) {
          this.formRequest.onError(error);
        }
      },
    );
  }

  private checkForControlErrors() {
    Object.values(this.form.controls).forEach(control => {
      control.updateValueAndValidity();
    });
  }

}
