<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button shape="round" size="large" fill="clear" (click)="closeModal()">
        <bhvmat-icon name="close" slot="icon-only"></bhvmat-icon>
      </ion-button>
    </ion-buttons>

    <ion-title>
      <span>Fórum de sugestões</span>
    </ion-title>

  </ion-toolbar>
</ion-header>

<ion-content>
  <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe>
</ion-content>


