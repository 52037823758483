import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { MaterialLibModule } from '@bhave/material-lib';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';

import { environment } from 'src/environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PhoneNumberDialogModule } from '@modules/phone-number-dialog/phone-number-dialog.module';
import { SentryErrorHandler } from '@services/sentry-error-handler.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationTokenInterceptor } from './interceptors/authentication-token.interceptor';
import { ContentTypeInterceptor } from './interceptors/content-type.interceptor';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'md',
      animated: true,
      swipeBackEnabled: true,
    }),
    MaterialLibModule,
    PhoneNumberDialogModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [
    ContentTypeInterceptor,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ContentTypeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationTokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: environment.production ? SentryErrorHandler : ErrorHandler },
  ],
})
export class AppModule { }
