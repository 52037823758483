import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { NavController } from '@ionic/angular';

import { SessionQuery } from '@state/session.query';

@Injectable({
  providedIn: 'root',
})
export class NoAccessGuard implements CanActivate {

  constructor(
    private readonly sessionQuery: SessionQuery,
    private readonly navController: NavController,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.sessionQuery.getUser();

    if (!user.can_access_business_institution && !user.can_access_personal_institution) {
      this.navController.navigateRoot(['sem-acesso']);
    }

    if (state.url.startsWith('/estudantes')) {
      const isCurrentInstitutionPersonal = this.sessionQuery.getCurrentInstitution()
        && !this.sessionQuery.getCurrentInstitution().is_business;

      if (isCurrentInstitutionPersonal && !user.can_access_personal_institution) {
        this.navController.navigateRoot(['instituicoes']);
      }

      if (!isCurrentInstitutionPersonal && !user.can_access_business_institution) {
        this.navController.navigateRoot(['instituicoes']);
      }
    }

    return true;
  }

}
