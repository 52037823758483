import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, NavigationStart, Router, RouterStateSnapshot } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { OverlayBaseController } from '@ionic/angular/dist/util/overlay';

@Injectable({
  providedIn: 'root',
})
export class BackGuard implements CanDeactivate<boolean> {
  private navigationStartEvent: NavigationStart;
  private readonly overlayControllers: OverlayBaseController<any, any>[];

  constructor(
    private readonly alertController: AlertController,
    private readonly modalControler: ModalController,
    private readonly popoverController: PopoverController,
    private readonly router: Router,
  ) {
    this.overlayControllers = [
      this.popoverController,
      this.alertController,
      this.modalControler,
    ];
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
  ): Promise<boolean> | boolean {

    if (this.isGoingBack()) {
      return this.resolveOverlayCtrl([...this.overlayControllers], currentState);
    }

    this.dismissOverlays();

    return true;
  }

  dismissOverlays() {
    this.overlayControllers.forEach(currentController => {
      currentController.getTop().then(overlay => {
        if (overlay !== undefined) {
          overlay.dismiss();
        }
      });
    });
  }

  isGoingBack(): boolean {
    return this.navigationStartEvent.navigationTrigger === 'popstate';
  }

  resolveOverlayCtrl<T extends OverlayBaseController<any, any>>(controllers: T[], currentState: RouterStateSnapshot) {
    const currentController = controllers.pop();
    if (currentController === undefined) {
      return true;
    }

    return currentController.getTop().then(overlay => {
      if (overlay === undefined) {
        return this.resolveOverlayCtrl(controllers, currentState);
      }

      overlay.dismiss();
      window.history.pushState({}, '', currentState.url);

      return false;
    });
  }

  start() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.navigationStartEvent = event;
      }
    });
  }

}
