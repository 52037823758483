import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

import { Institution } from '@models/institution.model';
import { LoginData } from '@models/login-data.model';
import { Membership } from '@models/membership.model';
import { User } from '@models/user.model';

export interface SessionState {
  currentInstitution: Institution;
  currentMembership: Membership;
  token: string;
  user: User;
}

export function createInitialState(): SessionState {
  return {
    currentInstitution: undefined,
    currentMembership: undefined,
    token: undefined,
    user: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {

  constructor() {
    super(createInitialState());
  }

  login(data: LoginData) {
    this.update({
      ...data,
    });
  }

  logout() {
    this.update(createInitialState());
  }

  setCurrentInstitution(currentInstitution: Institution) {
    this.update(state => {
      let currentMembership: Membership;

      if (currentInstitution !== undefined) {
        currentMembership = state.user.memberships.find(
          membership => membership.institution.id === currentInstitution.id,
        );
      }

      return { currentInstitution, currentMembership };
    });
  }

  setCurrentMembership(currentMembership: Membership) {
    this.update({ currentMembership });
  }

  updateUser(user: User): void {
    this.update({ user });
  }
}
