/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./dialog.component";
var styles_DialogComponent = [i0.styles];
var RenderType_DialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogComponent, data: {} });
export { RenderType_DialogComponent as RenderType_DialogComponent };
function View_DialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dialog-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 2, 0, currVal_0); }); }
function View_DialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dialog-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
function View_DialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-spinner", [["color", "primary"]], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(1, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null)], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-button", [["class", "button-square"], ["fill", "clear"]], [[2, "cancel", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.executeHandler(_v.context.$implicit.handler) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], disabled: [1, "disabled"], fill: [2, "fill"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "span", [], [[2, "text-hidden", null]], null, null, null, null)), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_DialogComponent_4)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = _v.context.$implicit.color; var currVal_2 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_v.context.$implicit.disabled)); var currVal_3 = "clear"; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); var currVal_6 = (i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_v.context.$implicit.loading)) === true); _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.role === "cancel"); _ck(_v, 0, 0, currVal_0); var currVal_4 = (i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_v.context.$implicit.loading)) === true); _ck(_v, 3, 0, currVal_4); var currVal_5 = _v.context.$implicit.text; _ck(_v, 5, 0, currVal_5); }); }
export function View_DialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "dialog-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "dialog-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "dialog-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogComponent_3)), i1.ɵdid(9, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.localButtons; var currVal_3 = _co.trackByFn; _ck(_v, 9, 0, currVal_2, currVal_3); }, null); }
export function View_DialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog", [], null, null, null, View_DialogComponent_0, RenderType_DialogComponent)), i1.ɵdid(1, 114688, null, 0, i5.DialogComponent, [i3.PopoverController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogComponentNgFactory = i1.ɵccf("app-dialog", i5.DialogComponent, View_DialogComponent_Host_0, { buttons: "buttons", header: "header", message: "message" }, {}, ["*"]);
export { DialogComponentNgFactory as DialogComponentNgFactory };
