import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialLibModule } from '@bhave/material-lib';
import { SharedModule } from '@modules/shared/shared.module';
import { TextMaskModule } from 'angular2-text-mask';
import { PhoneNumberDialogComponent } from './phone-number-dialog.component';



@NgModule({
  declarations: [ PhoneNumberDialogComponent ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialLibModule,
    SharedModule,
    TextMaskModule,
  ],
  exports: [ PhoneNumberDialogComponent ],
  entryComponents: [ PhoneNumberDialogComponent ],
})
export class PhoneNumberDialogModule { }
