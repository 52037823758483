import { NavController } from '@ionic/angular';
import { SessionQuery } from '@state/session.query';
import * as i0 from "@angular/core";
import * as i1 from "../state/session.query";
import * as i2 from "@ionic/angular";
export class NoAccessGuard {
    constructor(sessionQuery, navController) {
        this.sessionQuery = sessionQuery;
        this.navController = navController;
    }
    canActivate(route, state) {
        const user = this.sessionQuery.getUser();
        if (!user.can_access_business_institution && !user.can_access_personal_institution) {
            this.navController.navigateRoot(['sem-acesso']);
        }
        if (state.url.startsWith('/estudantes')) {
            const isCurrentInstitutionPersonal = this.sessionQuery.getCurrentInstitution()
                && !this.sessionQuery.getCurrentInstitution().is_business;
            if (isCurrentInstitutionPersonal && !user.can_access_personal_institution) {
                this.navController.navigateRoot(['instituicoes']);
            }
            if (!isCurrentInstitutionPersonal && !user.can_access_business_institution) {
                this.navController.navigateRoot(['instituicoes']);
            }
        }
        return true;
    }
}
NoAccessGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NoAccessGuard_Factory() { return new NoAccessGuard(i0.ɵɵinject(i1.SessionQuery), i0.ɵɵinject(i2.NavController)); }, token: NoAccessGuard, providedIn: "root" });
