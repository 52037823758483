import { Injectable } from '@angular/core';

import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Student } from '@models/student.model';

export interface StudentsState extends EntityState<Student>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'students' })
export class StudentsStore extends EntityStore<StudentsState, Student> {

  constructor() {
    super();
  }

}
