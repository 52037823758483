import { onlyDigits } from '@utils/convertions';
import * as moment from 'moment';
export class CustomValidators {
    static cellPhone(control) {
        const cellPhone = onlyDigits(control.value);
        if (cellPhone === '') {
            return undefined;
        }
        const minLength = 10;
        const maxLength = 11;
        const cellPhoneError = { cellPhone: true };
        if (cellPhone.length < minLength || cellPhone.length > maxLength) {
            return cellPhoneError;
        }
    }
    static phoneNumber(control) {
        const phoneNumber = onlyDigits(control.value);
        if (phoneNumber === '') {
            return undefined;
        }
        const phoneNumberLength = 10;
        if (phoneNumber.length !== phoneNumberLength) {
            return { phoneNumber: true };
        }
    }
    static cpf(control) {
        const cpf = control.value.replace(/\D/g, '');
        const requiredLength = 11;
        const idxModifier = 2;
        const cpfError = { cpf: true, message: 'CPF Inválido' };
        if (cpf.length !== requiredLength) {
            return Object.assign({}, cpfError, { cpf: { requiredLength } });
        }
        // Verifica se todos os dígitos são iguais.
        if (/^([0-9])\1*$/.test(cpf)) {
            return Object.assign({}, cpfError, { cpf: { equalDigits: true } });
        }
        const buildDigit = (arr) => {
            const digit = arr
                .map((val, idx) => val * (idx + idxModifier))
                .reduce((total, current) => total + current) % requiredLength;
            if (digit < idxModifier) {
                return digit < idxModifier ? 0 : requiredLength - digit;
            }
            return requiredLength - digit;
        };
        // A seguir é realizado o cálculo verificador.
        const cpfArr = cpf.split('').reverse().slice(idxModifier);
        cpfArr.unshift(buildDigit(cpfArr));
        cpfArr.unshift(buildDigit(cpfArr));
        if (cpf !== cpfArr.reverse().join('')) {
            // Dígito verificador não é válido, resultando em falha.
            return Object.assign({}, cpfError, { cpf: { digit: true } });
        }
        return undefined;
    }
    static matchPassword(control) {
        const password = control.get('new_password').value;
        const password_confirm = control.get('confirm_password').value;
        if (password !== password_confirm) {
            control.get('confirm_password')
                .setErrors({ matchPassword: true, message: 'As senhas devem ser idênticas' });
        }
        else {
            return undefined;
        }
    }
    static matchPassword2(control) {
        const password = control.get('password').value;
        const password_confirm = control.get('password_confirm').value;
        if (password !== password_confirm) {
            control.get('password_confirm')
                .setErrors({ matchPassword: true, message: 'As senhas devem ser idênticas' });
        }
        else {
            return undefined;
        }
    }
    static ensureDatesChronology(beforeDate, afterDate) {
        return (form) => {
            const beforeControl = form.get(beforeDate.controlName);
            const afterControl = form.get(afterDate.controlName);
            if (!beforeControl || !afterControl) {
                return;
            }
            const beforeMoment = moment(beforeControl.value);
            const afterMoment = moment(afterControl.value);
            if (!beforeMoment.isValid() || !afterMoment.isValid()) {
                return;
            }
            if (beforeMoment.isSameOrAfter(afterMoment)) {
                afterControl.setErrors({ dateShouldBeAfter: { label: beforeDate.label } });
                beforeControl.setErrors({ dateShouldBeBefore: { label: afterDate.label } });
            }
            else {
                if (afterControl.hasError('dateShouldBeAfter')) {
                    afterControl.updateValueAndValidity();
                }
                if (beforeControl.hasError('dateShouldBeBefore')) {
                    beforeControl.updateValueAndValidity();
                }
            }
        };
    }
}
const ɵ0 = () => `Este campo é obrigatório.`, ɵ1 = (errorData) => `Tamanho mínimo de ${errorData.requiredLength} caracteres.`, ɵ2 = (errorData) => `Tamanho máximo de ${errorData.requiredLength} caracteres.`, ɵ3 = () => `E-mail inválido.`, ɵ4 = () => `As senhas devem ser idênticas.`, ɵ5 = () => `CPF inválido`, ɵ6 = () => `CNPJ inválido`, ɵ7 = () => `Celular inválido`, ɵ8 = () => `Telefone inválido`, ɵ9 = (errorData) => `${errorData.message}`, ɵ10 = (errorData) => `Insira uma data antes de "${errorData.label}"`, ɵ11 = (errorData) => `Insira uma data depois de "${errorData.label}"`;
const VALIDATION_ERRORS = {
    required: ɵ0,
    minlength: ɵ1,
    maxlength: ɵ2,
    email: ɵ3,
    matchPassword: ɵ4,
    cpf: ɵ5,
    cnpj: ɵ6,
    cellPhone: ɵ7,
    phoneNumber: ɵ8,
    submitError: ɵ9,
    dateShouldBeBefore: ɵ10,
    dateShouldBeAfter: ɵ11,
    default: `Campo inválido`,
};
const HTTP_RESPONSE_ERRORS = {
    offline: 'Verifique sua conexão e tente novamente',
    default: 'Erro ao tentar enviar. Tente novamente',
};
export function getHttpErrorMessage(errorKey) {
    const error = HTTP_RESPONSE_ERRORS[errorKey];
    return error ? error : HTTP_RESPONSE_ERRORS.default;
}
export function getErrorMessage(errorKey, errorData) {
    const error = VALIDATION_ERRORS[errorKey];
    return error ? error(errorData) : VALIDATION_ERRORS.default;
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
