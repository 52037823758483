import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SessionStore } from './../state/session.store';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../state/session.store";
export class UsersService {
    constructor(http, sessionStore) {
        this.http = http;
        this.sessionStore = sessionStore;
    }
    acceptPaymentTerms(user) {
        return this.http.put(`${environment.api_url}users/me/accept-payment-term`, user).pipe(tap(updateduser => this.sessionStore.updateUser(Object.assign({}, user, updateduser))));
    }
    acceptInvite(id, key, user) {
        return this.http.post(`${environment.api_url}invite/${id}/${key}/`, user);
    }
    getInvitationData(id, key) {
        return this.http.get(`${environment.api_url}invite/${id}/${key}`);
    }
    updateUser(user) {
        return this.http.patch(`${environment.api_url}users/${user.id}/`, user).pipe(tap(updateduser => this.sessionStore.updateUser(Object.assign({}, user, updateduser))));
    }
    getUser() {
        return this.http.get(`${environment.api_url}users/me`).pipe(tap(user => this.sessionStore.updateUser(user)));
    }
    refreshUser() {
        this.getUser().subscribe();
    }
    startAccountDeleteProcess() {
        return this.http.post(`${environment.api_url}users/me/start-delete-account-process`, {});
    }
    verifyAccountDeleteKey(id, key) {
        return this.http.get(`${environment.api_url}users/me/process-delete-account/${id}/${key}`);
    }
    confirmAccountDeleteProcess(form, id, key) {
        return this.http.post(`${environment.api_url}users/me/process-delete-account/${id}/${key}/`, JSON.stringify(form));
    }
}
UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SessionStore)); }, token: UsersService, providedIn: "root" });
