import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SessionStore } from '@state/session.store';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/angular";
import * as i3 from "../state/session.store";
export class AuthenticationService {
    constructor(http, navController, sessionStore) {
        this.http = http;
        this.navController = navController;
        this.sessionStore = sessionStore;
    }
    login(form) {
        return this.http.post(`${environment.api_url}auth/login/`, JSON.stringify(form))
            .pipe(tap(response => this.sessionStore.login(response)));
    }
    logout() {
        this.navController.navigateRoot('login').then(() => this.sessionStore.logout());
    }
    updatePassword(form) {
        return this.http
            .put(`${environment.api_url}users/me/change-password`, JSON.stringify(form))
            .pipe(tap(() => this.sessionStore.logout()));
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NavController), i0.ɵɵinject(i3.SessionStore)); }, token: AuthenticationService, providedIn: "root" });
