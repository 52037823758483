import { HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '@services/authentication.service';
import { SessionQuery } from '@state/session.query';
import { HttpStatusCode } from '@utils/constants/http-status-code';
import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
import * as i2 from "../state/session.query";
export class AuthenticationTokenInterceptor {
    constructor(authenticationService, sessionQuery) {
        this.authenticationService = authenticationService;
        this.sessionQuery = sessionQuery;
    }
    intercept(request, next) {
        if (this.sessionQuery.getToken() === undefined) {
            return next.handle(request);
        }
        const authReq = request.clone({
            headers: request.headers.set('Authorization', `Token ${this.sessionQuery.getToken()}`),
        });
        return next.handle(authReq).pipe(tap(() => { }, (error) => {
            if (error instanceof HttpErrorResponse) {
                if (error.status === HttpStatusCode.UNAUTHORIZED) {
                    this.authenticationService.logout();
                }
            }
        }));
    }
}
AuthenticationTokenInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationTokenInterceptor_Factory() { return new AuthenticationTokenInterceptor(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.SessionQuery)); }, token: AuthenticationTokenInterceptor, providedIn: "root" });
