import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { SnackbarService } from '@services/snackbar.service';
import { SessionQuery } from '@state/session.query';

@Injectable({
  providedIn: 'root',
})
export class StaffUserGuard implements CanActivate {

  constructor(
    private readonly sessionQuery: SessionQuery,
    private readonly router: Router,
    private readonly snackbarService: SnackbarService,
  ) {}

  canActivate(): boolean | UrlTree {
    if (! this.sessionQuery.getUser().is_staff) {
      this.snackbarService.createErrorSnack('Não autorizado!');

      return this.router.parseUrl('/');
    }

    return true;
  }

}
