<div class="dialog-wrapper">
  <div *ngIf="header" class="dialog-header">
    <h2>{{ header }}</h2>
  </div>

  <div class="dialog-body">
    <div *ngIf="message" class="dialog-message">{{ message }}</div>
    <ng-content></ng-content>
  </div>

  <div class="dialog-buttons">
    <ion-button *ngFor="let button of localButtons; trackBy: trackByFn"
      [class.cancel]="button.role === 'cancel'"
      (click)="executeHandler(button.handler)"
      [disabled]="button.disabled | async"
      class="button-square"
      [color]="button.color"
      fill="clear">
      <span [class.text-hidden]="(button.loading | async) === true">{{ button.text }}</span>
      <ion-spinner color="primary" *ngIf="(button.loading | async) === true"></ion-spinner>
    </ion-button>
  </div>
</div>
