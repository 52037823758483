import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ParamRegexGuard implements CanActivate {

  constructor(
    private readonly navController: NavController,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const params = route.data.params as any[];
    const regexList = route.data.regexList;
    const redirectTo = route.data.redirectTo;

    for (let i = 0; i < params.length; i++) {
      const paramValue = String(route.params[params[i]]);

      if (!Boolean(paramValue.match(regexList[i]))) {
        if (redirectTo !== undefined) {
          this.navController.navigateRoot([redirectTo]);
        }

        return false;
      }
    }

    return true;
  }
}
