/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./app.component";
import * as i6 from "@bhave/material-lib";
import * as i7 from "@ionic-native/splash-screen/ngx/index";
import * as i8 from "@ionic-native/status-bar/ngx/index";
import * as i9 from "./guards/back.guard";
import * as i10 from "./state/session.query";
import * as i11 from "@angular/platform-browser";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ion-app", [], null, null, null, i1.View_IonApp_0, i1.RenderType_IonApp)), i0.ɵdid(1, 49152, null, 0, i2.IonApp, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(2, 16777216, null, 0, 1, "ion-router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i2.IonRouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], [8, null], i2.Config, i2.NavController, i4.Location, i0.ElementRef, i3.Router, i0.NgZone, i3.ActivatedRoute, [3, i2.IonRouterOutlet]], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i5.AppComponent, [i6.LoadingSpinnerService, i2.Platform, i7.SplashScreen, i8.StatusBar, i9.BackGuard, i2.PopoverController, i10.SessionQuery, i3.Router, i11.Title], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
