import { Injectable } from '@angular/core';

import { ID, Query, toBoolean } from '@datorama/akita';

import { Institution } from '@models/institution.model';
import { JobTitle, Membership } from '@models/membership.model';
import { User } from '@models/user.model';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NotFoundError } from '../errors/not-found.error';
import { SessionState, SessionStore } from './session.store';

@Injectable({
  providedIn: 'root',
})
export class SessionQuery extends Query<SessionState> {

  currentInstitution$ = this.select('currentInstitution');

  constructor(protected store: SessionStore) {
    super(store);
  }

  getCurrentInstitution(): Institution {
    return this.getValue().currentInstitution;
  }

  getCurrentMembership(): Membership {
    return this.getValue().currentMembership;
  }

  getMembershipByInstitutionId(institutionId: ID) {
    const membership = this.getMemberships().find(m => m.institution.id === institutionId);

    if (membership === undefined) {
      throw new NotFoundError('The Membership was not found for the Institution ID specified.');
    }

    return membership;
  }

  getMemberships(): Membership[] {
    return this.getValue().user.memberships;
  }

  getPersonalInstitution(): Institution {
    return this.getPersonalMembership().institution;
  }

  getPersonalMembership(): Membership {
    return this.getMemberships().find(membership => {
      return Number(membership.job_title) === JobTitle.Criador && !membership.institution.is_business;
    });
  }

  getToken(): string {
    return this.getValue().token;
  }

  getUser(): User {
    return this.getValue().user;
  }

  isCurrentInstitutionPersonal(): boolean {
    const membership = this.getCurrentMembership();

    return  Boolean(membership) &&
            Number(membership.job_title) === JobTitle.Criador &&
            !membership.institution.is_business;
  }

  selectIsCurrentInstitutionPersonal(): Observable<boolean> {
    return this.selectCurrentMembership().pipe(
      map(membership => Boolean(membership) && Number(membership.job_title) === JobTitle.Criador && !membership.institution.is_business),
    );
  }

  isLoggedIn() {
    return toBoolean(this.getValue().user);
  }

  selectCurrentMembership(): Observable<Membership> {
    return this.select(state => state.currentMembership);
  }

  selectInvitesCount(): Observable<number> {
    return this.selectUser().pipe(
      filter(user => user !== undefined),
      map(user => user.invites),
    );
  }

  selectMemberships(): Observable<Membership[]> {
    return this.selectUser().pipe(
      filter(user => user !== undefined),
      map(user => user.memberships),
    );
  }

  selectUser(): Observable<User> {
    return this.select(state => state.user);
  }
}
