import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-feedback-forum',
  templateUrl: './feedback-forum.component.html',
  styleUrls: ['./feedback-forum.component.scss'],
})
export class FeedbackForumComponent implements OnInit {
  url = 'https://feedback.userreport.com/e96dbc8b-5b4a-4060-abb2-10135f3e46c2/';
  urlSafe: SafeResourceUrl;

  constructor(
    public sanitizer: DomSanitizer,
    private readonly modalController: ModalController,
    ) { }

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
   this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
