import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthData } from '@models/auth-data.model';
import { Invite } from '@models/invite.model';
import { User } from '@models/user.model';

import { DeleteAccount } from '@models/delete-account.model';
import { environment } from '../../environments/environment';
import { SessionStore } from './../state/session.store';


@Injectable({
  providedIn: 'root',
})
export class UsersService {

  constructor(
    private readonly http: HttpClient,
    private readonly sessionStore: SessionStore,
  ) { }

  acceptPaymentTerms(user: User): Observable<User> {
    return this.http.put<User>(`${environment.api_url}users/me/accept-payment-term`, user).pipe(
      tap(updateduser => this.sessionStore.updateUser({...user, ...updateduser})),
    );
  }

  acceptInvite(id: number, key: string, user: User): Observable<any> {
    return this.http.post(`${environment.api_url}invite/${id}/${key}/`, user);
  }

  getInvitationData(id: number, key: string): Observable<Invite> {
    return this.http.get<Invite>(`${environment.api_url}invite/${id}/${key}`);
  }

  updateUser(user: User): Observable<User> {
    return this.http.patch<User>(`${environment.api_url}users/${user.id}/`, user).pipe(
      tap(updateduser => this.sessionStore.updateUser({...user, ...updateduser})),
    );
  }

  getUser(): Observable<User> {
    return this.http.get<User>(`${environment.api_url}users/me`).pipe(
      tap(user => this.sessionStore.updateUser(user)),
    );
  }

  refreshUser(): void {
    this.getUser().subscribe();
  }

  startAccountDeleteProcess(): Observable<any> {
    return this.http.post(`${environment.api_url}users/me/start-delete-account-process`, {});
  }

  verifyAccountDeleteKey(id: string, key: string): Observable<DeleteAccount> {
    return this.http.get<DeleteAccount>(`${environment.api_url}users/me/process-delete-account/${id}/${key}`);
  }

  confirmAccountDeleteProcess(form: AuthData, id: string, key: string): Observable<any> {
    return this.http.post(`${environment.api_url}users/me/process-delete-account/${id}/${key}/`, JSON.stringify(form));
  }
}
