import * as tslib_1 from "tslib";
import { Store, StoreConfig } from '@datorama/akita';
import * as i0 from "@angular/core";
export function createInitialState() {
    return {
        currentInstitution: undefined,
        currentMembership: undefined,
        token: undefined,
        user: undefined,
    };
}
let SessionStore = class SessionStore extends Store {
    constructor() {
        super(createInitialState());
    }
    login(data) {
        this.update(Object.assign({}, data));
    }
    logout() {
        this.update(createInitialState());
    }
    setCurrentInstitution(currentInstitution) {
        this.update(state => {
            let currentMembership;
            if (currentInstitution !== undefined) {
                currentMembership = state.user.memberships.find(membership => membership.institution.id === currentInstitution.id);
            }
            return { currentInstitution, currentMembership };
        });
    }
    setCurrentMembership(currentMembership) {
        this.update({ currentMembership });
    }
    updateUser(user) {
        this.update({ user });
    }
};
SessionStore.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionStore_Factory() { return new SessionStore(); }, token: SessionStore, providedIn: "root" });
SessionStore = tslib_1.__decorate([
    StoreConfig({ name: 'session' }),
    tslib_1.__metadata("design:paramtypes", [])
], SessionStore);
export { SessionStore };
