import { ID } from '@datorama/akita';

import { Institution } from './institution.model';
import { User } from './user.model';

export enum JobTitle {
  'Criador' = 1,
  'Administrador' = 2,
  'Analista' = 3,
  'Aplicador' = 4,
}

export interface Membership {
  id: ID;
  institution: Institution;
  job_title: JobTitle;
  member: User | ID;
}

export function compareMembershipInstitutionName(a: Membership, b: Membership): number {
  return a.institution.name.localeCompare(b.institution.name, undefined, { numeric: true });
}

export function compareMembershipAlphabetically(a: Membership, b: Membership): number {
  return (a.member as User).full_name.localeCompare((b.member as User).full_name, undefined, { numeric: true });
}

export function compareMembershipJobTitle(a: Membership, b: Membership): number {
  const A = a.job_title;
  const B = b.job_title;

  return A === B ? 0 : A > B ? 1 : -1;
}
