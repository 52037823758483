import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { Student } from '@models/student.model';
import { StudentsStore } from '@state/students.store';
@Injectable({
  providedIn: 'root',
})
export class StudentsService {
  public students: Student[] = [];

  constructor(
    private readonly studentsStore: StudentsStore,
    private readonly http: HttpClient,
  ) { }

  create(student: Student): Observable<Student> {
    return this.http.post<Student>(`${environment.api_url}students/`, JSON.stringify(student))
      .pipe(
        tap(createdStudent => this.studentsStore.add(createdStudent)),
      );
  }

  delete(student: Student, password: string): Observable<any> {
    return this.http.post(`${environment.api_url}students/${student.id}/delete/`, { password })
      .pipe(
        tap(() => this.studentsStore.remove(student.id)),
      );
  }

  list(): Observable<Student[]> {
    this.studentsStore.setLoading(true);

    return this.http.get<Student[]>(`${environment.api_url}students/?include_archived=true`)
      .pipe(
        tap(students => this.studentsStore.set(students)),
        finalize(() => this.studentsStore.setLoading(false)),
      );
  }

  update(student: Student): Observable<Student> {
    return this.http.patch<Student>(
      `${environment.api_url}students/${student.id}/?include_archived=true`,
      JSON.stringify(student),
    ).pipe(
      tap(updatedStudent => this.studentsStore.update(updatedStudent.id, updatedStudent)),
    );
  }
}
