import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Observable } from 'rxjs';

export interface ContextMenuItem {
  checkbox?: boolean;
  checked?: boolean;
  dismissOnClick?: boolean;
  iconName?: string;
  isVisible?: boolean;
  title?: string;
  type?: MenuItemType;
  routerLink?: string[];
  badgeValue?: number | Observable<number>;
  action?(data?: any): any;
}

enum MenuItemType {
  button = 'button',
  checkbox = 'checkbox',
}

const DEFAULT_MENU_ITEM: ContextMenuItem = {
  isVisible: true,
  type: MenuItemType.button,
  dismissOnClick: true,
};

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit {

  canShow: boolean;
  enableCheckboxes: boolean;
  enableIcons: boolean;
  @Input() items: ContextMenuItem[];
  localItems: ContextMenuItem[];

  constructor(private readonly popOver: PopoverController) { }

  checkboxClicked(event: CustomEvent, item: ContextMenuItem) {
    this.itemClicked(item, event.detail.checked);
  }

  itemClicked(item: ContextMenuItem, data?: any): void {
    if (item.action !== undefined) {
      item.action(data);
    }

    if (item.dismissOnClick) {
      this.popOver.dismiss();
    }
  }

  ngOnInit(): void {
    this. localItems = this.items.map(item => {
      return { ...DEFAULT_MENU_ITEM, ...item };
    }).filter(item => item.isVisible);
  }

  trackByFn(index: any) {
    return index;
  }
}
