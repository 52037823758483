
export const MONTH_SHORT_NAME_LENGTH = 3;

export const MONTH_NAMES = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const MONTH_SHORT_NAMES = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];

export const HTML_DATE_FORMAT = 'YYYY-MM-DD';
export const HTML_DATE_FORMAT_FULL = 'DD-MM-YYYY h:mm:ss';

export const DISPLAY_DATE_FORMAT = 'DD-MM-YYYY';
export const DISPLAY_DATE_FORMAT_FULL = 'DD-MM-YYYY h:mm:ss';
