import { NavController } from '@ionic/angular';
import { SessionQuery } from '@state/session.query';
import * as i0 from "@angular/core";
import * as i1 from "../state/session.query";
import * as i2 from "@ionic/angular";
export class AuthGuard {
    constructor(sessionQuery, navController) {
        this.sessionQuery = sessionQuery;
        this.navController = navController;
    }
    canActivate(route, state) {
        if (this.sessionQuery.isLoggedIn()) {
            return true;
        }
        this.navController.navigateRoot(['/login', { returnUrl: route.params.returnUrl || state.url }]);
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.SessionQuery), i0.ɵɵinject(i2.NavController)); }, token: AuthGuard, providedIn: "root" });
