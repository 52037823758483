import { ComponentFactoryResolver, Directive, ElementRef, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { FormControlName } from '@angular/forms';

import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { FormErrorComponent } from '@bhave/material-lib';
import { getErrorMessage } from '@utils/forms/custom-validators';

const DEBOUNCE_TIME = 300;

@Directive({
  selector: '[appErrorMessage]',
})
export class ErrorMessageDirective implements OnInit, OnDestroy {

  inputElement: HTMLInputElement;
  formErrorElement: HTMLElement;

  controlSubcription: Subscription;

  constructor(
    private readonly factoryResolver: ComponentFactoryResolver,
    private readonly elementRef: ElementRef,
    private readonly formControlName: FormControlName,
    private readonly viewContainer: ViewContainerRef,
    private readonly renderer: Renderer2,
  ) { }

  ngOnInit() {
    const control = this.formControlName.control;
    const componentFactory = this.factoryResolver.resolveComponentFactory(FormErrorComponent);
    const componentRef = this.viewContainer.createComponent(componentFactory);

    this.inputElement = this.elementRef.nativeElement;
    this.formErrorElement = componentRef.location.nativeElement;

    this.controlSubcription = control.statusChanges.pipe(
      debounceTime(DEBOUNCE_TIME),
    ).subscribe(() => {
      if (control.invalid) {
        const firstErrorKey = Object.keys(control.errors)[0];
        const firstErrorData = control.errors[firstErrorKey];
        this.setError(getErrorMessage(firstErrorKey, firstErrorData));
      } else {
        this.clearErrorStatus();
      }
    });

  }

  setError(error: string) {
    this.renderer.addClass(this.inputElement.parentElement, 'error');
    this.formErrorElement.innerHTML = error;
  }

  clearErrorStatus() {
    this.renderer.removeClass(this.inputElement.parentElement, 'error');
    this.formErrorElement.innerHTML = '';
  }

  ngOnDestroy(): void {
    this.controlSubcription.unsubscribe();
  }
}
