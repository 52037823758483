import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';

import { AuthenticationService } from '@services/authentication.service';
import { SessionQuery } from '@state/session.query';
import { HttpStatusCode } from '@utils/constants/http-status-code';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationTokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly sessionQuery: SessionQuery,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.sessionQuery.getToken() === undefined) {
      return next.handle(request);
    }

    const authReq = request.clone({
      headers: request.headers.set('Authorization', `Token ${this.sessionQuery.getToken()}`),
    });

    return next.handle(authReq).pipe(
      tap(
        () => {},
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === HttpStatusCode.UNAUTHORIZED) {
              this.authenticationService.logout();
            }
          }
        },
      ),
    );
  }
}
