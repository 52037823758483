import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { NavController } from '@ionic/angular';
import { Institution } from '@models/institution.model';
import { SessionQuery } from '@state/session.query';
import { SessionStore } from '@state/session.store';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstitutionResolveService implements Resolve<Observable<Institution>> {

  constructor(
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStore: SessionStore,
    private readonly navController: NavController,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Institution> {
    const fullfill$ = new Subject<Institution>();

    try {
      const institutionId = Number(route.params.institutionId);
      const membership = this.sessionQuery.getMembershipByInstitutionId(institutionId);
      this.sessionStore.setCurrentInstitution(membership.institution);
      fullfill$.next(membership.institution);
      fullfill$.complete();
    } catch (error) {
      this.navController.navigateRoot(['pagina-nao-encontrada']).then(
        () => this.sessionStore.setCurrentInstitution(undefined),
      );
    }

    return fullfill$;
  }

}
