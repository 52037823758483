import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthData } from '@models/auth-data.model';
import { LoginData } from '@models/login-data.model';
import { SessionStore } from '@state/session.store';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {
  constructor(
    private readonly http: HttpClient,
    private readonly navController: NavController,
    private readonly sessionStore: SessionStore,
  ) {}

  login(form: AuthData): Observable<LoginData> {
    return this.http.post<LoginData>(`${environment.api_url}auth/login/`, JSON.stringify(form))
      .pipe(
        tap(response => this.sessionStore.login(response)),
      );
  }

  logout() {
    this.navController.navigateRoot('login').then(
      () => this.sessionStore.logout(),
    );
  }

  updatePassword(form): Observable<any> {
    return this.http
      .put(`${environment.api_url}users/me/change-password`, JSON.stringify(form))
      .pipe(
        tap(() => this.sessionStore.logout()),
      );
  }
}
