import { ToastController } from '@ionic/angular';
import { IonColorName } from '@modules/shared/common-types';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
const DEFAULT_SNACK_OPTIONS = {
    duration: 3000,
    message: '',
    color: IonColorName.dark,
    showCloseButton: false,
};
export class SnackbarService {
    constructor(toastService) {
        this.toastService = toastService;
    }
    createSnack(messageOrOptions) {
        let options = Object.assign({}, DEFAULT_SNACK_OPTIONS);
        if (typeof (messageOrOptions) === 'string') {
            options.message = messageOrOptions;
        }
        else {
            options = Object.assign({}, options, messageOrOptions);
        }
        return Promise.resolve(this.toastService.create(Object.assign({}, options, { closeButtonText: 'Fechar', mode: 'ios' })).then(toast => {
            toast.present();
            return toast.onDidDismiss();
        }));
    }
    createErrorSnack(messageOrOptions) {
        const options = typeof (messageOrOptions) === 'string' ? { message: messageOrOptions } : messageOrOptions;
        return this.createSnack(Object.assign({ duration: 5000 }, options, { color: IonColorName.danger }));
    }
}
SnackbarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnackbarService_Factory() { return new SnackbarService(i0.ɵɵinject(i1.ToastController)); }, token: SnackbarService, providedIn: "root" });
