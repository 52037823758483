import { HttpClient } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { StudentsStore } from '@state/students.store';
import * as i0 from "@angular/core";
import * as i1 from "../state/students.store";
import * as i2 from "@angular/common/http";
export class StudentsService {
    constructor(studentsStore, http) {
        this.studentsStore = studentsStore;
        this.http = http;
        this.students = [];
    }
    create(student) {
        return this.http.post(`${environment.api_url}students/`, JSON.stringify(student))
            .pipe(tap(createdStudent => this.studentsStore.add(createdStudent)));
    }
    delete(student, password) {
        return this.http.post(`${environment.api_url}students/${student.id}/delete/`, { password })
            .pipe(tap(() => this.studentsStore.remove(student.id)));
    }
    list() {
        this.studentsStore.setLoading(true);
        return this.http.get(`${environment.api_url}students/?include_archived=true`)
            .pipe(tap(students => this.studentsStore.set(students)), finalize(() => this.studentsStore.setLoading(false)));
    }
    update(student) {
        return this.http.patch(`${environment.api_url}students/${student.id}/?include_archived=true`, JSON.stringify(student)).pipe(tap(updatedStudent => this.studentsStore.update(updatedStudent.id, updatedStudent)));
    }
}
StudentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StudentsService_Factory() { return new StudentsService(i0.ɵɵinject(i1.StudentsStore), i0.ɵɵinject(i2.HttpClient)); }, token: StudentsService, providedIn: "root" });
