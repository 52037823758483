import { NavController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class ParamRegexGuard {
    constructor(navController) {
        this.navController = navController;
    }
    canActivate(route) {
        const params = route.data.params;
        const regexList = route.data.regexList;
        const redirectTo = route.data.redirectTo;
        for (let i = 0; i < params.length; i++) {
            const paramValue = String(route.params[params[i]]);
            if (!Boolean(paramValue.match(regexList[i]))) {
                if (redirectTo !== undefined) {
                    this.navController.navigateRoot([redirectTo]);
                }
                return false;
            }
        }
        return true;
    }
}
ParamRegexGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ParamRegexGuard_Factory() { return new ParamRegexGuard(i0.ɵɵinject(i1.NavController)); }, token: ParamRegexGuard, providedIn: "root" });
