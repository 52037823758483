import { Component } from '@angular/core';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform, PopoverController } from '@ionic/angular';

import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingSpinnerService } from '@bhave/material-lib';
import { BackGuard } from '@guards/back.guard';
import { PhoneNumberDialogComponent } from '@modules/phone-number-dialog/phone-number-dialog.component';
import { SessionQuery } from '@state/session.query';
import { filter } from 'rxjs/operators';

enum AppTitle {
  bhaveGestor = 'bHave Gestor',
  bhaveParents = 'bHave Parents',
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  private readonly spinnerWait = 2000;

  constructor(
    private readonly loadingService: LoadingSpinnerService,
    private readonly platform: Platform,
    private readonly splashScreen: SplashScreen,
    private readonly statusBar: StatusBar,
    private readonly backGuard: BackGuard,
    private readonly popoverController: PopoverController,
    private readonly sessionQuery: SessionQuery,
    private readonly router: Router,
    private readonly titleService: Title,
  ) {
    this.initializeApp();
    this.backGuard.start();
  }

  initializeApp() {
    this.startTitleHandling();

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.loadingService.createSpinner(this.spinnerWait);
      this.checkUserCellphone();
    });
  }

  checkUserCellphone() {
    this.sessionQuery.selectUser().pipe(
      filter(user => user && !user.phone_number_main),
    ).subscribe(() => this.popoverController
      .create({component: PhoneNumberDialogComponent})
      .then(popover => popover.present()),
    );
  }

  startTitleHandling() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      const newTitle = event.urlAfterRedirects.includes('bhave-parents') ? AppTitle.bhaveParents : AppTitle.bhaveGestor;
      if (newTitle !== this.titleService.getTitle()) {
        this.titleService.setTitle(newTitle);
      }
    });
  }
}
