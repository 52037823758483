import { Router } from '@angular/router';
import { SnackbarService } from '@services/snackbar.service';
import { SessionQuery } from '@state/session.query';
import * as i0 from "@angular/core";
import * as i1 from "../state/session.query";
import * as i2 from "@angular/router";
import * as i3 from "../services/snackbar.service";
export class StaffUserGuard {
    constructor(sessionQuery, router, snackbarService) {
        this.sessionQuery = sessionQuery;
        this.router = router;
        this.snackbarService = snackbarService;
    }
    canActivate() {
        if (!this.sessionQuery.getUser().is_staff) {
            this.snackbarService.createErrorSnack('Não autorizado!');
            return this.router.parseUrl('/');
        }
        return true;
    }
}
StaffUserGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StaffUserGuard_Factory() { return new StaffUserGuard(i0.ɵɵinject(i1.SessionQuery), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.SnackbarService)); }, token: StaffUserGuard, providedIn: "root" });
