import { NavController } from '@ionic/angular';
import { SessionQuery } from '@state/session.query';
import { SessionStore } from '@state/session.store';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../state/session.query";
import * as i2 from "../state/session.store";
import * as i3 from "@ionic/angular";
export class InstitutionResolveService {
    constructor(sessionQuery, sessionStore, navController) {
        this.sessionQuery = sessionQuery;
        this.sessionStore = sessionStore;
        this.navController = navController;
    }
    resolve(route) {
        const fullfill$ = new Subject();
        try {
            const institutionId = Number(route.params.institutionId);
            const membership = this.sessionQuery.getMembershipByInstitutionId(institutionId);
            this.sessionStore.setCurrentInstitution(membership.institution);
            fullfill$.next(membership.institution);
            fullfill$.complete();
        }
        catch (error) {
            this.navController.navigateRoot(['pagina-nao-encontrada']).then(() => this.sessionStore.setCurrentInstitution(undefined));
        }
        return fullfill$;
    }
}
InstitutionResolveService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InstitutionResolveService_Factory() { return new InstitutionResolveService(i0.ɵɵinject(i1.SessionQuery), i0.ɵɵinject(i2.SessionStore), i0.ɵɵinject(i3.NavController)); }, token: InstitutionResolveService, providedIn: "root" });
