import * as i0 from "@angular/core";
export class ContentTypeInterceptor {
    intercept(request, next) {
        const contentRequest = request.clone({
            headers: request.headers.set('Content-Type', 'application/json'),
        });
        return next.handle(contentRequest);
    }
}
ContentTypeInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContentTypeInterceptor_Factory() { return new ContentTypeInterceptor(); }, token: ContentTypeInterceptor, providedIn: "root" });
