/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/@bhave/material-lib/bhave-material-lib.ngfactory";
import * as i6 from "@bhave/material-lib";
import * as i7 from "./tooltip.component";
var styles_TooltipComponent = [i0.styles];
var RenderType_TooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipComponent, data: {} });
export { RenderType_TooltipComponent as RenderType_TooltipComponent };
function View_TooltipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 2, 0, currVal_0); }); }
export function View_TooltipComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-card-content", [["color", "secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonCardContent_0, i2.RenderType_IonCardContent)), i1.ɵdid(1, 49152, null, 0, i3.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "pre", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "bhvmat-icon", [["name", "close"], ["size", "xsmall"]], null, null, null, i5.View_IconComponent_0, i5.RenderType_IconComponent)), i1.ɵdid(8, 114688, null, 0, i6.IconComponent, [i1.ElementRef], { name: [0, "name"], size: [1, "size"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 4, 0, currVal_0); var currVal_2 = "close"; var currVal_3 = "xsmall"; _ck(_v, 8, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.text; _ck(_v, 6, 0, currVal_1); }); }
export function View_TooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tooltip", [], null, null, null, View_TooltipComponent_0, RenderType_TooltipComponent)), i1.ɵdid(1, 49152, null, 0, i7.TooltipComponent, [i3.PopoverController], null, null)], null, null); }
var TooltipComponentNgFactory = i1.ɵccf("app-tooltip", i7.TooltipComponent, View_TooltipComponent_Host_0, { header: "header", text: "text" }, {}, []);
export { TooltipComponentNgFactory as TooltipComponentNgFactory };
