import { Query, toBoolean } from '@datorama/akita';
import { JobTitle } from '@models/membership.model';
import { filter, map } from 'rxjs/operators';
import { NotFoundError } from '../errors/not-found.error';
import { SessionStore } from './session.store';
import * as i0 from "@angular/core";
import * as i1 from "./session.store";
export class SessionQuery extends Query {
    constructor(store) {
        super(store);
        this.store = store;
        this.currentInstitution$ = this.select('currentInstitution');
    }
    getCurrentInstitution() {
        return this.getValue().currentInstitution;
    }
    getCurrentMembership() {
        return this.getValue().currentMembership;
    }
    getMembershipByInstitutionId(institutionId) {
        const membership = this.getMemberships().find(m => m.institution.id === institutionId);
        if (membership === undefined) {
            throw new NotFoundError('The Membership was not found for the Institution ID specified.');
        }
        return membership;
    }
    getMemberships() {
        return this.getValue().user.memberships;
    }
    getPersonalInstitution() {
        return this.getPersonalMembership().institution;
    }
    getPersonalMembership() {
        return this.getMemberships().find(membership => {
            return Number(membership.job_title) === JobTitle.Criador && !membership.institution.is_business;
        });
    }
    getToken() {
        return this.getValue().token;
    }
    getUser() {
        return this.getValue().user;
    }
    isCurrentInstitutionPersonal() {
        const membership = this.getCurrentMembership();
        return Boolean(membership) &&
            Number(membership.job_title) === JobTitle.Criador &&
            !membership.institution.is_business;
    }
    selectIsCurrentInstitutionPersonal() {
        return this.selectCurrentMembership().pipe(map(membership => Boolean(membership) && Number(membership.job_title) === JobTitle.Criador && !membership.institution.is_business));
    }
    isLoggedIn() {
        return toBoolean(this.getValue().user);
    }
    selectCurrentMembership() {
        return this.select(state => state.currentMembership);
    }
    selectInvitesCount() {
        return this.selectUser().pipe(filter(user => user !== undefined), map(user => user.invites));
    }
    selectMemberships() {
        return this.selectUser().pipe(filter(user => user !== undefined), map(user => user.memberships));
    }
    selectUser() {
        return this.select(state => state.user);
    }
}
SessionQuery.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionQuery_Factory() { return new SessionQuery(i0.ɵɵinject(i1.SessionStore)); }, token: SessionQuery, providedIn: "root" });
