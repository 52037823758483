import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContentTypeInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const contentRequest = request.clone({
      headers: request.headers.set('Content-Type', 'application/json'),
    });

    return next.handle(contentRequest);
  }
}
