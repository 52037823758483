import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@guards/auth.guard';
import { BackGuard } from '@guards/back.guard';
import { NoAccessGuard } from '@guards/no-access.guard';
import { ParamRegexGuard } from '@guards/param-regex.guard';
import { StaffUserGuard } from '@guards/staff-user.guard';
import { InstitutionResolveService } from '@services/institution-resolve.service';
import { StudentsResolveService } from '@services/students-resolve.service';

const routes: Routes = [
  { path: 'como-excluir-sua-conta', loadChildren: () => import('@modules/lgpd/lgpd.page.module').then(m => m.LgpdPageModule) },

  {
    path: 'bhave-parents',
    loadChildren: () => import('@modules/bhave-parents/bhave-parents.module').then( m => m.BhaveParentsPageModule),
  },

  {
    path: 'admin',
    loadChildren: () => import('@modules/internal-admin/internal-admin.module').then( m => m.InternalAdminPageModule),
    canActivate: [ AuthGuard, StaffUserGuard ],
  },

  {
    path: 'suporte',
    loadChildren: () => import('@modules/staff/staff.module').then(m => m.StaffPageModule),
    canActivate: [ AuthGuard, StaffUserGuard ],
  },

  { path: 'evento', loadChildren: () => import('@modules/evento/evento.module').then(m => m.EventoPageModule) },

  { path: 'pagina-nao-encontrada', loadChildren: () => import('@modules/not-found/not-found.module').then(m => m.NotFoundPageModule) },

  {
    path: 'convite',
    loadChildren: () => import('@modules/registration-invite/registration-invite.module').then(m => m.RegistrationInviteModule),
  },

  {
    path: 'esqueci-minha-senha',
    loadChildren: () => import('@modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
  },
  {
    path: 'redefinir-senha',
    loadChildren: () => import('@modules/redefine-password/redefine-password.module').then(m => m.RedefinePasswordModule),
  },

  { path: 'login', loadChildren: () => import('@modules/login/login.page.module').then(m => m.LoginPageModule) },

  {
    path: 'sem-acesso',
    loadChildren: () => import('@modules/no-access/no-access.module').then(m => m.NoAccessPageModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'instituicoes',
    loadChildren: () => import('@modules/institution-select/institution-select.module').then(m => m.InstitutionSelectPageModule),
    canActivate: [AuthGuard, NoAccessGuard],
  },

  {
    path: ':institutionId',
    canActivate: [AuthGuard, ParamRegexGuard, NoAccessGuard],
    canDeactivate: [BackGuard],
    data: { regexList: ['^([0-9]+)$'], params: ['institutionId'], redirectTo: 'pagina-nao-encontrada' },
    resolve: { institution: InstitutionResolveService, students: StudentsResolveService },
    loadChildren: () => import('@modules/main-layout/main-layout.module').then(m => m.MainLayoutModule),
  },

  {
    path: 'remover-conta',
    loadChildren: () => import('@modules/delete-account/delete-account.module').then(m => m.DeleteAccountPageModule),
  },

  { path: '**', redirectTo: 'login' },

];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
})
export class AppRoutingModule { }
