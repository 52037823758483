import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { PopoverController } from '@ionic/angular';

import { User } from '@models/user.model';
import { DialogButton, IonButtonRole, IonColorName } from '@modules/shared/common-types';
import { SnackbarService } from '@services/snackbar.service';
import { UsersService } from '@services/users.service';
import { SessionQuery } from '@state/session.query';
import { onlyDigits } from '@utils/convertions';
import { BaseForm, FormRequest } from '@utils/forms/base-form';
import { CustomValidators } from '@utils/forms/custom-validators';
import { TEL_MASK } from '@utils/forms/masks';

const SNACK_DURATION = 5000;

@Component({
  selector: 'app-phone-number-dialog',
  templateUrl: './phone-number-dialog.component.html',
  styleUrls: ['./phone-number-dialog.component.scss'],
})
export class PhoneNumberDialogComponent extends BaseForm<User> {
  form: FormGroup;
  telMask = { mask: TEL_MASK, placeholderChar: '\u2000' };

  dialogButtons: DialogButton[] = [
    {
      text: 'depois',
      role: IonButtonRole.cancel,
      disabled: this.sending$,
    },
    {
      text: 'salvar',
      handler: () => {
        this.submit();

        return false;
      },
      disabled: this.sending$,
      loading: this.sending$,
    },
  ];

  constructor(
    private readonly userService: UsersService,
    private readonly sessionQuery: SessionQuery,
    private readonly popoverController: PopoverController,
    private readonly snackbarService: SnackbarService,
    ) {
      super();
      this.form = this.formBuilder.group({
        phone_number_main: ['', [Validators.required, CustomValidators.cellPhone]],
      });
    }

  preProcessSubmit() {
    return this.userService.updateUser({
      ...this.sessionQuery.getUser(),
      phone_number_main: onlyDigits(this.form.value.phone_number_main),
    });
  }

  protected get formRequest(): FormRequest<User> {
    return {
      request:   () => this.preProcessSubmit(),
      onSuccess: () => {
        this.popoverController.dismiss();
        this.snackbarService.createSnack({
          message: 'Telefone salvo! Você pode acessar seu perfil para atualizar suas informações quando quiser.',
          duration: SNACK_DURATION,
          color: IonColorName.success,
        });
      },
      onError: () => {
        this.snackbarService.createSnack({
          message: 'Não conseguimos salvar seu telefone agora. Tentaremos depois.',
          duration: SNACK_DURATION,
          color: IonColorName.danger,
        });
        this.popoverController.dismiss();
      },
    };
  }

}
