import { NavigationStart, Router } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/router";
export class BackGuard {
    constructor(alertController, modalControler, popoverController, router) {
        this.alertController = alertController;
        this.modalControler = modalControler;
        this.popoverController = popoverController;
        this.router = router;
        this.overlayControllers = [
            this.popoverController,
            this.alertController,
            this.modalControler,
        ];
    }
    canDeactivate(component, currentRoute, currentState) {
        if (this.isGoingBack()) {
            return this.resolveOverlayCtrl([...this.overlayControllers], currentState);
        }
        this.dismissOverlays();
        return true;
    }
    dismissOverlays() {
        this.overlayControllers.forEach(currentController => {
            currentController.getTop().then(overlay => {
                if (overlay !== undefined) {
                    overlay.dismiss();
                }
            });
        });
    }
    isGoingBack() {
        return this.navigationStartEvent.navigationTrigger === 'popstate';
    }
    resolveOverlayCtrl(controllers, currentState) {
        const currentController = controllers.pop();
        if (currentController === undefined) {
            return true;
        }
        return currentController.getTop().then(overlay => {
            if (overlay === undefined) {
                return this.resolveOverlayCtrl(controllers, currentState);
            }
            overlay.dismiss();
            window.history.pushState({}, '', currentState.url);
            return false;
        });
    }
    start() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.navigationStartEvent = event;
            }
        });
    }
}
BackGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BackGuard_Factory() { return new BackGuard(i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i1.PopoverController), i0.ɵɵinject(i2.Router)); }, token: BackGuard, providedIn: "root" });
