import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

import { getErrorMessage } from '@utils/forms/custom-validators';

export class FormErrors {
  protected form: FormGroup;

  public setErrorMessage(field: string): void {
    const control: AbstractControl = this.getFieldControl(field);

    if (control && control.errors) {
      const error: string = String(Object.keys(control.errors));
      control.errors.message = getErrorMessage(error, control.errors[error]);
    }
  }

  protected getFieldControl(field: string): AbstractControl {
    const control = this.form.get(field);

    return control || undefined;
  }

  protected handleSubmitError(response: HttpErrorResponse): void {
    const data = response.error;
    const fields = Object.keys(data);

    if (String(fields) === 'non_field_errors') {
      this.setNonFieldErrors(data);
    } else {
      this.setFieldErrors(fields, data);
    }
  }

  private fetchFieldErrors(messages: ValidationErrorsArray, field: string): ValidationErrors {
    return messages[field].length > 0
      ? { submitError: { fieldName: field, message: messages[field][0] }}
      : {};
  }

  private setFieldErrors(fields: string[], data: ValidationErrorsArray): void {
    fields.forEach(field => {
      const control = this.getFieldControl(field);
      if (control) {
        const errors = this.fetchFieldErrors(data, field);
        control.setErrors(errors);
      }
    });
  }

  private setNonFieldErrors(data: object): void {
    const errors = {};

    Object.keys(data).forEach(d => {
      errors[d] = true;
      errors['message'] = data[d];
    });

    this.form.setErrors(errors);
  }
}

interface ValidationErrorsArray extends ValidationErrors {
  [key: string]: string[];
}
