import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { StudentsService } from './students.service';

@Injectable({
  providedIn: 'root',
})
export class StudentsResolveService implements Resolve<any> {

  constructor(
    private readonly studentsService: StudentsService,
  ) { }

  resolve() {
    this.studentsService.list().subscribe();

    return undefined;
  }
}
