import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { NavController } from '@ionic/angular';

import { SessionQuery } from '@state/session.query';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private readonly sessionQuery: SessionQuery,
    private readonly navController: NavController,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.sessionQuery.isLoggedIn()) {
      return true;
    }
    this.navController.navigateRoot(['/login', { returnUrl: route.params.returnUrl || state.url }]);
  }
}
