import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { debounceTime } from 'rxjs/operators';

import { enableAkitaProdMode, persistState } from '@datorama/akita';
import * as localForage from 'localforage';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

// clear user's localStorage
localStorage.removeItem('AkitaStores');

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'Akita',
  version: 1,
  storeName: 'akita-storage',
});

const DEBOUNCE_TO_PUSH_TO_MEMORY = 2500;
persistState({
  storage: localForage,
  preStorageUpdateOperator: () => debounceTime(DEBOUNCE_TO_PUSH_TO_MEMORY),
});

/* tslint:disable */
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
/* tslint:enable */
